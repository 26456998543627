import { SitePublicView } from './../../core/models/site-list.model';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { SiteAutocomplete } from "./site-autocomplete.model";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { SiteList } from "../../core/models/site-list.model";
import { SiteService } from "../../core/services/site.service";
import { ObjectHelper } from '../../core/helpers/object.helper';
import { StringHelper } from "../../core/helpers/string-helper";

@Component({
  selector: 'app-site-autocomplete',
  templateUrl: './site-autocomplete.component.html',
  styleUrls: ['./site-autocomplete.component.scss']
})
export class SiteAutocompleteComponent implements OnInit, AfterViewInit {
  @ViewChild('autoCompleteContainer') autoCompleteContainer: ElementRef;
  @ViewChild('input') input: ElementRef;
  @ViewChild('dropdown') dropdown: ElementRef;

  @Output() valueChanged = new EventEmitter();

  @Input('form') targetForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() selected: SiteAutocomplete = null;
  @Input() placeholder: string;
  @Input() trialFilter: string;
  @Input() disabled: boolean;
  @Input() allowAllSites = true;
  @Input() restrictToUnassignedSites = false;
  @Input() showApprovedOnly = false;
  @Input() showSiteNumbers = false;
  @Input() publicList: boolean;
  @Input() truncateMaxChars = 0;
  @Input() appendCountryCode = false;
  @Input() showConsumerSites = false;
  @Input() excludeAddressFromLabel = false;
  @Input() disableFilterOnInit = false;

  form: UntypedFormGroup;

  minChars = 3;
  filteredOptions: SiteAutocomplete[] = [];
  resultsAreLoading = false;
  showDropdown = false;
  searchHasValue = false;
  showAllSites = false;
  dropdownUpwards = false;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.input !== undefined) {
      if (!(this.input.nativeElement.contains(event.target) || this.autoCompleteContainer.nativeElement.contains(event.target))) {
        this.showDropdown = false;
      }
    }
  }

  constructor(private _siteService: SiteService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      input: new UntypedFormControl({ value: '', disabled: this.disabled }, [Validators.required, this.optionSelectedValidator.bind(this)])
    });
  }

  ngAfterViewInit(): void {
    this.form.get('input').valueChanges.subscribe(value => {
      if (value.length >= this.minChars) {
        if (value !== '') {
          if (!this.disableFilterOnInit)
            this.filter(value);

          this.searchHasValue = true;
        } else {
          this.searchHasValue = false;
        }
      } else if (value.length === 0 && value !== this.form.get('input').value) {
        if (!this.disableFilterOnInit)
          this.filter(null);

        this.searchHasValue = false;
      }
    });

    if (!this.disableFilterOnInit)
      this.filter(null);

    this.cd.detectChanges();
  }

  adjustDropdownPosition() {
    const dropdownEl = this.dropdown.nativeElement;
    const bounding = dropdownEl.getBoundingClientRect();
    const windowHeight = window.innerHeight;

    // Check if dropdown goes out of the viewport
    if (bounding.bottom > windowHeight) {
      this.dropdownUpwards = true; // Set flag to open the dropdown upwards
    } else {
      this.dropdownUpwards = false; // Default to downwards
    }
  }

  setTrialId(trialId: string): void {
    this.trialFilter = trialId;
    this.showAllSites = false;
    this.searchHasValue = false;

    if (trialId !== null && trialId !== '') {
      this.disabled = false;
      this.filter(null);
      this.searchHasValue = false;
    }
  }

  enable(): void {
    this.disabled = false;
    this.form.get('input').enable();
  }

  onShowAll(): void {
    this.showAllSites = true;
    this.filter(this.form.get('input').value);
  }

  onShowTrialSites(): void {
    this.showAllSites = false;
    this.filter(this.form.get('input').value);
  }

  optionSelectedValidator(control: UntypedFormControl): { [s: string]: boolean } | null {
    if (this.selected === null || this.selected === undefined) {
      return { 'noOptionSelected': true };
    }

    return null;
  }

  openDropdown(): void {
    this.onClick();

    this.filter(null);
  }

  onClick(): void {
    if (!this.disabled) {
      this.showDropdown = !this.showDropdown;

      if (this.showDropdown) {
        if (this.trialFilter === null || this.trialFilter === '') {
          this.filteredOptions = [];
        }

        setTimeout(() => {
          this.adjustDropdownPosition();
          this.input.nativeElement.focus();
        }, 500);
      }
    }
  }

  setInitialSelectedItem(selected: SiteAutocomplete) {
    this.selected = selected;
  }

  setInitialValue(id: string, text: string): void {
    this.selected = new SiteAutocomplete(id, text, '', '', '');
  }

  filter(value: string): void {
    if (!this.disabled) {
      let trialId = this.trialFilter;
      if (this.showAllSites)
        trialId = null;

      if (value === '')
        value = null;

      this.resultsAreLoading = true;

      if (this.publicList) {
        this._siteService.retrieveSitesPublic(!this.restrictToUnassignedSites ? trialId : null, value).subscribe({
          next: (response: SitePublicView[]) => {
            response.forEach(site => {
              let text = site.name + ' / ' + site.address;

              this.filteredOptions.push(
                new SiteAutocomplete(
                  site.id,
                  null,
                  text.substring(0, 30) + ' ...',
                  site.name,
                  site.address + ' ' + site.country,
                  null));
            })
          }
        })

        this.resultsAreLoading = false;
        return;
      }

      this._siteService.retrieveSites(1, !this.restrictToUnassignedSites ? trialId : null, value, null, false, 999).subscribe({
        next: (siteList: SiteList) => {
          this.filteredOptions = [];
          siteList.results.forEach(result => {
            let siteNumber = '';
            let visible = true;
            let text = result.name + ' / ' + result.address;
            if (this.excludeAddressFromLabel)
              text = result.name;

            let highlight = result.trials.find(trial => trial.id === this.trialFilter) === undefined;

            if (this.showSiteNumbers && !ObjectHelper.isUndefinedNullOrEmpty(this.trialFilter)) {
              const trial = result.trials.find(trial => trial.id === this.trialFilter);
              siteNumber = !ObjectHelper.isUndefinedOrNull(trial) && !ObjectHelper.isUndefinedOrNull(trial.siteNumber) ? trial.siteNumber : '';
              result.name = siteNumber !== null ? siteNumber + ' ' + result.name : result.name;
              if (this.showApprovedOnly && !ObjectHelper.isUndefinedOrNull(trial) && trial.approvalStatus !== 1) {
                visible = false;
                highlight = false;
              }
            }

            const maxChars = this.truncateMaxChars > 0 ? this.truncateMaxChars : 30;
            if ((!this.showConsumerSites && result.apiConsumerId === null) || this.showConsumerSites) {
              this.filteredOptions.push(new SiteAutocomplete(result.id, result.irgSiteId, StringHelper.limit(text, maxChars), result.name, result.address, highlight, visible, result.apiConsumerName));
            }
          });
          this.resultsAreLoading = false;
        },
        error: (error) => {
          this.resultsAreLoading = false;
        }
      });
    }
  }

  onAddAndSelectOption(option: SiteAutocomplete): void {
    this.filteredOptions = [];
    this.filteredOptions.push(option);
    this.onSelectOption(option);
  }

  onSelectOption(option: SiteAutocomplete): void {
    this.selected = option;
    this.selected.line1 = StringHelper.limit(option.line1, 45);
    this.targetForm.patchValue({ [this.controlName]: option.id });
    this.form.patchValue({ input: '' });
    this.valueChanged.emit(option);
  }

  onClearSelection(): void {
    this.selected = null;
    this.form.patchValue({ input: '' });
    this.targetForm.patchValue({ [this.controlName]: '' });
    this.valueChanged.emit(null);
  }

  disableInput() {
    this.form.controls.input.disable();
  }

  enableInput() {
    this.disabled = false;
    this.form.controls.input.enable();
  }

  clear() {
    this.selected = null;
    this.targetForm.patchValue({ [this.controlName]: null });
    this.form.patchValue({ input: '' });
    this.filteredOptions = [];
    this.filter(null);
  }

  truncate(str: string): string {
    if (this.truncateMaxChars === 0)
      return str;

    if (str.length < this.truncateMaxChars) {
      return str;
    }

    return str.slice(0, this.truncateMaxChars) + '...';
  }
}
