<div class="visit-table-cell-site-select"
     [class.editing]="editing"
     [class.editable]="editable && trialId"
     (mouseenter)="onMouseEnter()"
     (mouseleave)="onMouseLeave()">
  <span class="clear-selection" (click)="onClear()" *ngIf="allowClear && this.parentForm.get('siteId').value"><i class="far fa-times"></i></span>

  <span class="label" *ngIf="!editing" (click)="onEdit($event)">

    {{ getLabel() }}

    <span class="label-arrow" *ngIf="trialId">
      <i class="far fa-angle-down"></i>
    </span>

  </span>

  <span class="editing" [formGroup]="parentForm" *ngIf="editing">
    <app-site-autocomplete #siteSelect placeholder="Select a site..." [disableFilterOnInit]="true" [excludeAddressFromLabel]="true" [showConsumerSites]="true" [truncateMaxChars]="truncateChars > 0 ? truncateChars : null" [form]="parentForm" [controlName]="siteIdControlName" [trialFilter]="trialId" [allowAllSites]="false" (valueChanged)="onValueChanged($event)"></app-site-autocomplete>
  </span>

  <!-- Tooltip -->
  <div class="tooltip" *ngIf="showTooltip && getLabel(false).length > truncateChars" (click)="onEdit($event)">
    {{ getLabel(false) }}
  </div>
</div>
