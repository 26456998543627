import { ExpenseService } from 'app/core/services/expense.service';
import { TrialService } from './../../../core/services/trial.service';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ModalComponent } from "../../../shared/modal/modal.component";
import { ProjectManagementService } from "../../../core/services/project-management.service";
import { ReimbursementSearch } from "../../../core/models/reimbursement-search.model";
import { LogHelper } from "../../../core/helpers/log.helper";
import { RoleNames } from "../../../core/constants/role-names";
import { AuthService } from "../../../core/services/auth.service";
import { PatientService } from "../../../core/services/patient.service";
import { AlertService } from "../../../shared/alert/alert.service";
import { SelectOption } from 'app/core/models/select-option.model';
import {
  DeleteOverBudgetRequestRequest,
  GetOverBudgetRequestsRequest,
  OverBudgetRequestCategory,
  OverBudgetRequestItemsViewModel,
  OverBudgetRequestStatus,
  OverBudgetRequestsViewModel,
  ProjectManagementExportRequest,
  ReferBackOverBudgetRequestRequest
} from 'app/core/models/project-management.model';
import { ReimbursmentSearchCriteria } from "../../../core/models/project-management/reimbursment-search-criteria.model";
import { PatientAutoCompleteComponent } from "../../../shared/patient-autocomplete/patient-autocomplete.component";
import { VisitSearchCriteria } from "../../../core/models/visit-search-criteria.model";
import { VisitService } from "../../../core/services/visit.service";
import { TrialAutocompleteComponent } from "../../../shared/trial-autocomplete/trial-autocomplete.component";
import { DropdownInputComponent } from "../../../shared/dropdown-input/dropdown-input.component";
import { TrialAutocomplete } from "../../../shared/trial-autocomplete/trial-autocomplete.model";
import { ResponsePage } from 'app/core/models/search-page.model';
import { ActivatedRoute } from '@angular/router';
import { OrderBy } from 'app/core/models/expense.model';
import { enumToText } from 'app/core/helpers/enum-to-text.function';
import {
  UpdateEntityAmountRequest,
  UpdateEntityCurrencyRequest,
  UpdatePatientTripInvoicedAmountRequest,
  UpdatePatientTripInvoicedCurrencyRequest
} from 'app/core/models/patient.model';
import { SearchInputComponent } from 'app/shared/search-input/search-input.component';
import { InputSelectComponent } from "../../../shared/input-select/input-select.component";
import { SiteAutocompleteComponent } from "../../../shared/site-autocomplete/site-autocomplete.component";
import { DateHelper } from "../../../core/helpers/date-helper";

@Component({
  selector: 'app-project-management-list',
  templateUrl: './project-management-list.component.html',
  styleUrls: ['./project-management-list.component.scss']
})
export class ProjectManagementListComponent implements OnInit, AfterViewInit {
  @ViewChild('exportTypeSelect') exportTypeSelect: InputSelectComponent;
  @ViewChild('exportSiteAutocomplete') exportSiteAutocomplete: SiteAutocompleteComponent;
  @ViewChild('filterModal') filterModal: ModalComponent;
  @ViewChild('exportModal') exportModal: ModalComponent;
  @ViewChild('confirmDeleteModal') confirmDeleteModal: ModalComponent;
  @ViewChild('referBackOverBudgetRequestModal') referBackOverBudgetRequestModal: ModalComponent;
  @ViewChild('patientAutocomplete') patientAutocomplete: PatientAutoCompleteComponent;
  @ViewChild('trialAutocomplete') trialAutocomplete: TrialAutocompleteComponent;
  @ViewChild('exportTrialAutocomplete') exportTrialAutocomplete: TrialAutocompleteComponent;
  @ViewChild('visitSelect') visitSelect: DropdownInputComponent;
  @ViewChild('typeSelect') typeSelect: DropdownInputComponent;
  @ViewChild('orderBySelect') orderBySelect: DropdownInputComponent;
  @ViewChild('searchInput') searchInput: SearchInputComponent;
  processingRequest: boolean = false;
  OverBudgetRequestStatus = OverBudgetRequestStatus;
  OverBudgetRequestCategory = OverBudgetRequestCategory;

  searchForm: UntypedFormGroup;
  filterForm: UntypedFormGroup;
  exportForm: UntypedFormGroup;
  results: ReimbursementSearch = new ReimbursementSearch();
  overBudgetRequests: ResponsePage<OverBudgetRequestsViewModel>;
  selectedTab = 'trips_expenses';
  roleNames = RoleNames;
  forms: UntypedFormGroup[] = [];
  trials: SelectOption[] = [];
  patientVisits: SelectOption[] = [];
  types: SelectOption[] = [];
  filteredTrials: SelectOption[] = [];
  orderByOptions: SelectOption[] = [];
  overBudgetRequestItemsViewModels: Record<string, OverBudgetRequestItemsViewModel[]> = {};
  overBudgetRequestItemsLoading: Record<string, boolean> = {};
  showOverBudgetRequestItems: Record<string, boolean> = {};
  deleteOverBudgetRequestId: string;
  referBackOverBudgetRequestId: string;
  showOnlyOpenOverBudgetRequests: boolean = true;
  loading: boolean = false;
  exportTypeOptions: SelectOption[] = [];

  deleteOverBudgetRequestForm = new FormGroup({
    overBudgetRequestId: new FormControl(''),
    notes: new FormControl('', Validators.required)
  });

  referBackOverBudgetRequestForm = new FormGroup({
    overBudgetRequestId: new FormControl(''),
    notes: new FormControl('', Validators.required)
  });

  constructor(private _projectManagementService: ProjectManagementService, public authService: AuthService,
    private _visitService: VisitService, private _patientService: PatientService,
    private _alertService: AlertService, private trialService: TrialService,
    private route: ActivatedRoute, private expenseService: ExpenseService,
    private cd: ChangeDetectorRef) {

    this.types.push(new SelectOption('All', 'Travel & Reimbursement'));
    this.types.push(new SelectOption('Trips', 'Travel'));
    this.types.push(new SelectOption('Expenses', 'Reimbursement'));
  }

  ngOnInit(): void {
    this.initSearchForm();
    this.initFilterForm();
    this.initExportForm();
    this.initOrderBySelection();
    this.loadData(1);

    // When the filter start date changes, also apply it to the export filter
    this.filterForm.get('startDate').valueChanges.subscribe(value => {
      this.exportForm.patchValue({ startDate: value });
    });

    // When the filter end date changes, also apply it to the export filter
    this.filterForm.get('endDate').valueChanges.subscribe(value => {
      this.exportForm.patchValue({ endDate: value });
    });

    this.exportForm.get('trialId').valueChanges.subscribe(trialId => {
      if (trialId) {
        this.exportForm.patchValue({siteId: null});
        this.exportSiteAutocomplete.clear();
      }

      this.exportSiteAutocomplete.setTrialId(trialId)
    });

    this.exportForm.get('type').valueChanges.subscribe(type => {
      if (type === 'trips' || type === 'reimbursements') {
        this.exportForm.patchValue({
          allSites: false,
          allData: false
        });
      }
    });
  }

  initOrderBySelection() {
    this.orderByOptions.push({ value: OrderBy.Oldest.toString(), text: enumToText(OrderBy, OrderBy.Oldest) });
    this.orderByOptions.push({ value: OrderBy.Newest.toString(), text: enumToText(OrderBy, OrderBy.Newest) });
  }

  private getOverBudgetRequest() {
    let overBudgetRequestId = this.route.snapshot.queryParamMap.get('overBudgetRequestId');

    if (overBudgetRequestId === null) {
      return;
    }

    this.searchForm.get('searchTerm').setValue(overBudgetRequestId);
    this.searchForm.get('searchTerm').markAsDirty();
    this.searchInput.inputHasValue = true;
    this.onTabClick("over_budget_requests", overBudgetRequestId);
  }

  private initExportForm() {
    this.exportForm = new UntypedFormGroup({
      startDate: new UntypedFormControl(),
      endDate: new UntypedFormControl(),
      dropdownTrialIds: new UntypedFormControl([]),
      trialId: new UntypedFormControl('', [Validators.required]),
      type: new UntypedFormControl('reimbursements', [Validators.required]),
      siteId: new UntypedFormControl(),
      allSites: new UntypedFormControl(false),
      allData: new UntypedFormControl(false),
      diaryEnabled: new UntypedFormControl(false)
    });

    this.setExportTypeOptions();
  }

  private initFilterForm() {
    this.filterForm = new UntypedFormGroup({
      processing: new UntypedFormControl(false),
      startDate: new UntypedFormControl(),
      endDate: new UntypedFormControl(),
      trialCode: new UntypedFormControl(),
      trialId: new UntypedFormControl(),
      patientId: new UntypedFormControl(),
      visitId: new UntypedFormControl(),
      type: new UntypedFormControl('')
    });
  }

  private initSearchForm() {
    this.searchForm = new UntypedFormGroup({
      searchTerm: new UntypedFormControl(),
      showMyTrialsOnly: new UntypedFormControl(true)
    });
  }

  subscribeOrderByChanges() {
    this.orderBySelect?.selectValueChanged.subscribe(value => {
      localStorage.setItem("obr_order_by", value);

      this.loadOverBudgetRequests();
    })
  }

  setExportTypeOptions() {
    this.exportTypeOptions = [
      { value: 'reimbursements', text: 'Reimbursements and Travel'},
      { value: 'ob', text: 'Over Budget Approvals'}
    ];

    if (this.exportForm.get('diaryEnabled').value === true)
      this.exportTypeOptions.push({ value: 'diary', text: 'Maze e-diary'});
  }

  ngAfterViewInit(): void {
    // Add the full-width classname to the main container to force the table to fill the available space
    const mainContainer = document.querySelector('.main-container');
    mainContainer.classList.add('full-width');

    this.typeSelect.setValue('All')
    this.getOverBudgetRequest();
  }

  loadData(page: number) {
    this.forms = [];

    const criteria: ReimbursmentSearchCriteria = {
      searchTerm: this.searchForm.get('searchTerm').value,
      startDate: this.filterForm.get('startDate').value,
      endDate: this.filterForm.get('endDate').value,
      patientId: this.filterForm.get('patientId').value,
      visitId: this.filterForm.get('visitId').value,
      trialCode: this.filterForm.get('trialCode').value,
      type: this.filterForm.get('type').value,
      myTrials: this.searchForm.get('showMyTrialsOnly').value
    };

    // Sync the export form with the filter form
    this.exportForm.patchValue({
      startDate: this.filterForm.get('startDate').value,
      endDate: this.filterForm.get('endDate').value,
      dropdownTrialIds: [this.filterForm.get('trialId').value],
      trialIds: [this.filterForm.get('trialId').value]
    });
    this._projectManagementService.getReimbursements(criteria, page).subscribe({
      next: (response: ReimbursementSearch) => {
        this.results = response;
        response.results.forEach(result => {
          this.forms.push(new UntypedFormGroup({
            entityId: new UntypedFormControl(result.entityId),
            quotedAmount: new UntypedFormControl(this.penceToPounds(result.quotedAmount)),
            quotedCurrency: new UntypedFormControl(result.quotedCurrency),
            quotedAmountBc: new UntypedFormControl(this.penceToPounds(result.quotedAmountBC)),
            invoicedAmount: new UntypedFormControl(this.penceToPounds(result.invoicedAmount)),
            invoicedCurrency: new UntypedFormControl(result.invoicedCurrency),
            invoicedAmountBc: new UntypedFormControl(this.penceToPounds(result.invoicedAmountBC)),
          }));
        });
        this.filterForm.patchValue({ processing: false });
      },
      error: (error: any) => {
        LogHelper.log(error);
        this.filterForm.patchValue({ processing: false });
      }
    });
  }

  loadOverBudgetRequests(page: number = 1, overBudgetRequestId: string = null) {
    let request: GetOverBudgetRequestsRequest = {
      page: page,
      myTrials: this.searchForm.get('showMyTrialsOnly').value,
      search: this.searchForm.get('searchTerm').value,
      orderBy: localStorage.getItem("obr_order_by") === "0" ? OrderBy.Oldest : OrderBy.Newest,
      openOnly: this.showOnlyOpenOverBudgetRequests
    }

    this._projectManagementService.getOverBudgetRequests(request).subscribe({
      next: response => {
        this.overBudgetRequests = response;
        this.overBudgetRequests.results.forEach(r => {
          this.overBudgetRequestItemsLoading[r.id] = false;
          this.showOverBudgetRequestItems[r.id] = false;
        });

        if (overBudgetRequestId !== null) {
          this.getOverBudgetRequestItems(overBudgetRequestId);
        }
      },
      error: error => {
        this._alertService.showErrorResponse(error);
      }
    })
  }

  onFilterTrialChanged(selection: TrialAutocomplete): void {
    this.filterForm.patchValue({ trialId: selection.id });
  }

  onExportTrialChanged(selection: TrialAutocomplete): void {
    this.exportForm.patchValue({ trialId: selection.id, diaryEnabled: selection.diaryEnabled });

    this.setExportTypeOptions();
  }

  onTabClick(selected: string, overBudgetRequestId: string = null) {
    this.selectedTab = selected;

    if (this.selectedTab === 'over_budget_requests') {
      this.loadOverBudgetRequests(1, overBudgetRequestId);

      setTimeout(() => {
        this.orderBySelect?.setValue(localStorage.getItem("obr_order_by") ?? OrderBy.Newest.toString());
        this.subscribeOrderByChanges();
      }, 100);

      return;
    }

    this.loadData(1);
  }

  /**
   * Called when the search input has changed
   * @param searchTerm
   */
  onSearchInputChanged(searchTerm: string): void {
    if (searchTerm === '')
      this.searchForm.patchValue({ searchTerm: '' });

    if (this.selectedTab === 'trips_expenses') {
      this.loadData(1);
    } else {
      this.loadOverBudgetRequests();
    }
  }

  onChangePage(page: number): void {
    if (this.selectedTab === 'trips_expenses') {
      this.loadData(page);
    } else {
      this.loadOverBudgetRequests(page);
    }
  }

  onFilterData(): void {
    this.filterForm.patchValue({ processing: true });
    this.loadData(1);
    this.filterModal.hide();
  }

  onPatientSelected(selection: SelectOption): void {
    this.patientVisits = [];

    if (selection !== null) {
      this.loadPatientVisits();
    }
  }

  loadPatientVisits(): void {
    const criteria = new VisitSearchCriteria({
      page: 1,
      pageSize: 9999,
      myTasks: false,
      searchTerm: null,
      patientId: this.filterForm.get('patientId').value,
      trialId: null,
      startDate: null,
      endDate: null,
      bookingStatus: null,
      showAllOpenVisits: false,
      designatedContact: null
    });

    this._visitService.visits(criteria).subscribe({
      next: results => {
        results.results.forEach(visit => {
          this.patientVisits.push({ value: visit.id, text: visit.visitTitle });
        });
      },
      error: err => {
        LogHelper.log(err);
        this._alertService.showErrorAlert(err);
      }
    });
  }

  export() {
    this.processingRequest = true;
    const startDate = this.exportForm.get('startDate').value as Date;
    const endDate = this.exportForm.get('endDate').value as Date;

    let request: ProjectManagementExportRequest = {
      trialId: this.exportForm.get('trialId').value,
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null
    }

    const exportType = this.exportForm.get('type').value;

    switch (exportType) {
      case 'reimbursements':
        this.exportReimbursements(request);
        break;
      case 'ob':
        this.exportOverBudgetRequests(request);
        break;
      case 'diary':
        this.exportDiary();
        break;
    }
  }

  private exportDiary() {
    const from = this.exportForm.get('startDate').value;
    const to = this.exportForm.get('endDate').value;

    this.trialService.exportTrips(
      this.exportForm.get('trialId').value,
      DateHelper.dateToDdMmYyyy(from),
      DateHelper.dateToDdMmYyyy(to),
      'diary',
      this.exportForm.get('siteId').value
    ).subscribe({
      next: () => {
        this.onHideExportModal();
        this._alertService.showSuccessAlert('The export request was sent, please check your email.');
        this.processingRequest = false;
      },
      error: error => {
        this.processingRequest = false;
        this._alertService.showWarningAlert('There was a problem requesting the export.');
      }
    });
  }

  private exportOverBudgetRequests(request: ProjectManagementExportRequest) {
    this._projectManagementService.exportOverBudgetRequests(request).subscribe({
      next: () => {
        this._alertService.showSuccessAlert("The export request was sent, please check your email.");
        this.onHideExportModal();
        this.processingRequest = false;
      },
      error: err => {
        this._alertService.showWarningAlert("There was a problem requesting the export.");
        LogHelper.error(err);
        this.processingRequest = false;
      }
    });
  }

  private exportReimbursements(request: ProjectManagementExportRequest) {
    this._projectManagementService.exportReimbursements(request).subscribe({
      next: () => {
        this._alertService.showSuccessAlert("The export request was sent, please check your email.");
        this.onHideExportModal();
        this.processingRequest = false;
      },
      error: err => {
        this._alertService.showWarningAlert("There was a problem requesting the export.");
        LogHelper.error(err);
        this.processingRequest = false;
      }
    });
  }

  onResetFilter(): void {
    this.filterForm.reset();
    this.filterModal.hide();
    this.patientAutocomplete.onClear();
    this.trialAutocomplete.reset();
    this.visitSelect?.reset();
    this.typeSelect.setValue('All');

    // Also reset the values on the export
    this.exportForm.reset();

    this.loadData(1);
  }

  onHideExportModal(): void {
    this.exportForm.reset();
    this.exportTrialAutocomplete.reset();
    this.exportSiteAutocomplete.clear();
    this.trials = [];
    this.exportModal.hide();
  }

  onHandleViewMyTrials(): void {
    const showMyTrialsOnly = this.searchForm.get('showMyTrialsOnly').value;
    this.searchForm.patchValue({ showMyTrialsOnly: !showMyTrialsOnly });

    setTimeout(() => {
      if (this.selectedTab === 'trips_expenses') {
        this.loadData(1);
      } else {
        this.loadOverBudgetRequests();
      }
    }, 600);
  }

  onHandleViewOnlyOpenOverBudgetRequests(): void {
    this.showOnlyOpenOverBudgetRequests = !this.showOnlyOpenOverBudgetRequests;

    setTimeout(() => {
      this.loadOverBudgetRequests();
    }, 600);
  }

  camelCaseToSentence(text: string): string {
    if (text === null || text === undefined || text === '')
      return '';

    return text.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

  penceToPounds(amount: number): string {
    if (amount === null || amount === undefined)
      return '';

    const pounds = amount / 100;

    // Format the number to a string with commas as thousands separators and two decimal places
    return pounds.toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  showExportModal() {
    this.exportForm.reset();
    this.exportForm.get("startDate").setValue(this.filterForm.get("startDate").value);
    this.exportForm.get("endDate").setValue(this.filterForm.get("endDate").value);
    this.exportForm.get("trialId").setValue(this.filterForm.get("trialId").value)
    this.exportForm.get('allSites').setValue(false);
    this.exportForm.get('type').setValue('');
    if (this.trialAutocomplete.selected) {
      this.exportTrialAutocomplete.setInitialValue(this.trialAutocomplete.selected.id, this.trialAutocomplete.selected.label)
    }

    if (!this.trials.length) {
      this.trialService.getTrialsPublic(null, 9999).subscribe({
        next: trials => {
          trials.forEach(t => {
            this.trials.push(new SelectOption(t.id, t.label));
            this.filteredTrials.push(new SelectOption(t.id, t.label));
          })
        }
      });
    }

    this.exportModal.show();
  }

  filterTrials(searchTerm: string) {
    if (searchTerm.length) {
      this.filteredTrials = this.filteredTrials.filter(t => t.text.toLowerCase().includes(searchTerm.toLowerCase()));
    }
    else {
      this.filteredTrials = this.trials;
    }
  }

  onHandleTrialClicked(trialId: string): void {
    if (!Array.isArray(this.exportForm.get('trialIds').value))
      this.exportForm.patchValue({ trialIds: [] });

    let selectedTrialIds = this.exportForm.get('trialIds').value;

    if (selectedTrialIds.includes(trialId)) {
      selectedTrialIds.splice(selectedTrialIds.indexOf(trialId), 1);
    } else {
      selectedTrialIds.push(trialId);
    }

    this.exportForm.patchValue({
      trialIds: selectedTrialIds,
      dropdownTrialIds: selectedTrialIds
    });
  }

  displayTravelPurpose(purpose: string): string {
    if (purpose === undefined || purpose === null || purpose === '')
      return '';

    return purpose.replace(/([A-Z])/g, ' $1').trim();
  }

  displayStatus(type: string, status: string) {
    if (status === null || status === undefined || status === '')
      return '';

    if (type === 'Travel') {
      if (status == 'Booked') {
        return 'Booked';
      }

      return 'Pending';
    }

    switch (status) {
      case 'Pending':
        return 'Pending';
      case 'Booked':
        return 'Booked';
      case 'Paid':
        return 'Paid';
    }

    return "Approved";
  }

  displayPaymentMethod(paymentMethod: string): string {
    switch (paymentMethod) {
      case 'CaxtonCard':
        return 'Card';
      case 'ManualBankTransfer':
        return 'Bank';
      case 'CaxtonAutomatedBankTransfer':
        return 'Bank';
    }

    return "";
  }

  getOverBudgetRequestItems(overBudgetRequestId: string, refreshItems: boolean = false) {
    if (overBudgetRequestId in this.overBudgetRequestItemsViewModels && !refreshItems) {
      this.showOverBudgetRequestItems[overBudgetRequestId] = true;
      return;
    }

    this.overBudgetRequestItemsLoading[overBudgetRequestId] = true;
    this._projectManagementService.overBudgetRequestItems(overBudgetRequestId).subscribe({
      next: response => {
        this.overBudgetRequestItemsViewModels[overBudgetRequestId] = response;
        this.overBudgetRequestItemsLoading[overBudgetRequestId] = false;
        this.showOverBudgetRequestItems[overBudgetRequestId] = true;
      },
      error: error => {
        this.overBudgetRequestItemsLoading[overBudgetRequestId] = false;
        this._alertService.showErrorResponse(error.error);
      }
    });
  }

  hideOverBudgetRequestItems(overBudgetRequestId: string) {
    this.showOverBudgetRequestItems[overBudgetRequestId] = false;
  }

  onDeleteRequest(overBudgetRequestId: string) {
    this.deleteOverBudgetRequestForm.reset();
    this.deleteOverBudgetRequestId = overBudgetRequestId;
    this.confirmDeleteModal.show();
  }

  onReferBackRequest(overBudgetRequestId: string) {
    this.referBackOverBudgetRequestForm.reset();
    this.referBackOverBudgetRequestId = overBudgetRequestId;
    this.referBackOverBudgetRequestModal.show();
  }

  deleteOverBudgetRequest() {
    this.loading = true;
    let deleteOverBudgetRequestRequest: DeleteOverBudgetRequestRequest = this.deleteOverBudgetRequestForm.value as DeleteOverBudgetRequestRequest;
    deleteOverBudgetRequestRequest.overBudgetRequestId = this.deleteOverBudgetRequestId;

    this._projectManagementService.deleteOverBudgetRequest(deleteOverBudgetRequestRequest).subscribe({
      next: () => {
        this.overBudgetRequests.results = this.overBudgetRequests.results.filter(r => r.id !== this.deleteOverBudgetRequestId);
        this._alertService.showSuccessAlert("Over budget request successfully deleted.");
        this.loading = false;
        this.confirmDeleteModal.hide();
      },
      error: error => {
        this.loading = false;
        this._alertService.showErrorResponse(error.error);
      }
    })
  }

  referBackOverBudgetRequest() {
    this.loading = true;
    let referBackOverBudgetRequestRequest: ReferBackOverBudgetRequestRequest = this.referBackOverBudgetRequestForm.value as ReferBackOverBudgetRequestRequest;
    referBackOverBudgetRequestRequest.overBudgetRequestId = this.referBackOverBudgetRequestId;

    this._projectManagementService.referBackOverBudgetRequest(referBackOverBudgetRequestRequest).subscribe({
      next: () => {
        this.overBudgetRequests.results = this.overBudgetRequests.results.filter(r => r.id !== this.referBackOverBudgetRequestId);
        this._alertService.showSuccessAlert("Over budget request successfully referred back.");
        this.loading = false;
        this.referBackOverBudgetRequestModal.hide();
      },
      error: error => {
        this.loading = false;
        this._alertService.showErrorResponse(error.error);
      }
    })
  }

  onInvoicedAmountChanged(index: number) {
    const form = this.forms[index];

    const tripId = form.get('entityId').value;
    const invoicedAmount = form.get('invoicedAmount').value;

    const invoicedAmountNumber = invoicedAmount !== null && invoicedAmount !== '' ? parseFloat(invoicedAmount) * 100 : null;

    let request: UpdatePatientTripInvoicedAmountRequest = {
      entityId: tripId,
      amount: invoicedAmountNumber
    }

    this._patientService.updatePatientTripInvoicedAmount(request).subscribe({
      next: rsp => {
        this.results.results[index].invoicedAmountBC = rsp.amountBc;
        this.forms[index].get('invoicedAmountBc').setValue(+this.penceToPounds(rsp.amountBc));

        this._alertService.showSuccessAlert("Trip invoice amount successfully changed.");
      },
      error: err => {
        LogHelper.log(err);
        this._alertService.showErrorAlert(err);
      }
    });
  }

  onInvoicedCurrencyChanged(index: number) {
    const form = this.forms[index];

    const tripId = form.get('entityId').value;
    const invoicedCurrency = form.get('invoicedCurrency').value;

    let request: UpdatePatientTripInvoicedCurrencyRequest = {
      entityId: tripId,
      currency: invoicedCurrency
    }

    this._patientService.updatePatientTripInvoicedCurrency(request).subscribe({
      next: rsp => {
        this.results.results[index].invoicedAmountBC = rsp.amountBc;
        this.forms[index].get('invoicedAmountBc').setValue(+this.penceToPounds(rsp.amountBc));

        this._alertService.showSuccessAlert("Trip invoice currency successfully changed.");
      },
      error: err => {
        LogHelper.log(err);
        this._alertService.showErrorAlert(err);
      }
    });
  }

  onInvoicedAmountBcChanged(index: number) {
    const form = this.forms[index];

    const tripId = form.get('entityId').value;
    const invoicedAmountBc = form.get('invoicedAmountBc').value;

    const invoicedAmountBcNumber = invoicedAmountBc !== null && invoicedAmountBc !== '' ? parseFloat(invoicedAmountBc) * 100 : null;

    let request: UpdatePatientTripInvoicedAmountRequest = {
      entityId: tripId,
      amount: invoicedAmountBcNumber
    }

    this._patientService.updatePatientTripInvoicedAmountBc(request).subscribe({
      next: () => {
        this._alertService.showSuccessAlert("Trip invoice amount BC successfully changed.");
      },
      error: err => {
        LogHelper.log(err);
        this._alertService.showErrorAlert(err);
      }
    });
  }

  onQuotedAmountChanged(index: number, type: string) {
    const form = this.forms[index];

    const entityId = form.get('entityId').value;
    const quotedAmount = form.get('quotedAmount').value;

    const quotedAmountNumber = quotedAmount !== null && quotedAmount !== '' ? parseFloat(quotedAmount) * 100 : null;

    let request: UpdateEntityAmountRequest = {
      entityId: entityId,
      amount: quotedAmountNumber
    }

    if (type === 'Reimbursement') {
      this.expenseService.updateExpenseClaimAmount(request).subscribe({
        next: rsp => {
          this.results.results[index].quotedAmountBC = rsp.amountBc;
          this.forms[index].get('quotedAmountBc').setValue(+this.penceToPounds(rsp.amountBc));

          this.results.results[index].invoicedAmountBC = rsp.amountBc;
          this.forms[index].get('invoicedAmountBC').setValue(+this.penceToPounds(rsp.amountBc));

          this._alertService.showSuccessAlert("Expense claim amount successfully changed.");
        },
        error: err => {
          LogHelper.log(err);
          this._alertService.showErrorAlert(err);
        }
      });
    } else {
      this._patientService.updatePatientTripQuotedAmount(request).subscribe({
        next: rsp => {
          this.results.results[index].quotedAmountBC = rsp.amountBc;
          this.forms[index].get('quotedAmountBc').setValue(+this.penceToPounds(rsp.amountBc));

          this._alertService.showSuccessAlert("Invoice amount BC successfully changed.");
        },
        error: err => {
          LogHelper.log(err);
          this._alertService.showErrorAlert(err);
        }
      });
    }
  }

  onQuotedCurrencyChanged(index: number, type: string) {
    const form = this.forms[index];

    const entityId = form.get('entityId').value;
    const quotedCurrency = form.get('quotedCurrency').value;

    let request: UpdateEntityCurrencyRequest = {
      entityId: entityId,
      currency: quotedCurrency
    }

    if (type === 'Reimbursement') {
      this.expenseService.updateExpenseClaimCurrency(request).subscribe({
        next: rsp => {
          this.results.results[index].quotedAmountBC = rsp.amountBc;
          this.forms[index].get('quotedAmountBc').setValue(+this.penceToPounds(rsp.amountBc));

          this.results.results[index].invoicedAmountBC = rsp.amountBc;
          this.forms[index].get('invoicedAmountBc').setValue(+this.penceToPounds(rsp.amountBc));

          this.results.results[index].invoicedCurrency = quotedCurrency;
          this.forms[index].get('invoicedCurrency').setValue(quotedCurrency);

          this._alertService.showSuccessAlert("Expense claim currency successfully changed.");
        },
        error: err => {
          LogHelper.log(err);
          this._alertService.showErrorAlert(err);
        }
      });
    } else {
      this._patientService.updatePatientTripQuotedCurrency(request).subscribe({
        next: rsp => {
          this.results.results[index].quotedAmountBC = rsp.amountBc;
          this.forms[index].get('quotedAmountBc').setValue(+this.penceToPounds(rsp.amountBc));

          this._alertService.showSuccessAlert("Trip quote currency successfully changed.");
        },
        error: err => {
          LogHelper.log(err);
          this._alertService.showErrorAlert(err);
        }
      });
    }
  }

  onQuotedAmountBcChanged(index: number, type: string) {
    const form = this.forms[index];

    const entityId = form.get('entityId').value;
    const quotedAmountBc = form.get('quotedAmountBc').value;

    const quotedAmountBcNumber = quotedAmountBc !== null && quotedAmountBc !== '' ? parseFloat(quotedAmountBc) * 100 : null;

    let request: UpdateEntityAmountRequest = {
      entityId: entityId,
      amount: quotedAmountBcNumber
    }

    if (type === 'Reimbursement') {
      this.expenseService.updateExpenseClaimAmountBc(request).subscribe({
        next: () => {
          this._alertService.showSuccessAlert("Expense claim amount BC successfully changed.");
        },
        error: err => {
          LogHelper.log(err);
          this._alertService.showErrorAlert(err);
        }
      });
    } else {
      this._patientService.updatePatientTripQuotedAmountBc(request).subscribe({
        next: () => {
          this._alertService.showSuccessAlert("Trip quote amount BC successfully changed.");
        },
        error: err => {
          LogHelper.log(err);
          this._alertService.showErrorAlert(err);
        }
      });
    }
  }
}
