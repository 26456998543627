<div class="trials">

  <!-- Start: View head -->
  <div class="view-head">
    <div class="row">

      <div class="col-12 text-center">
        <h2 class="heading-small with-padding">Edit Trial Details</h2>
      </div>

    </div>
  </div>
  <!-- End: View head -->
  <!-- Start: View body -->
  <div class="view-body">

    <div class="row">
      <div class="col-12 col-md-8 offset-md-2 col-lg-6 offset-lg-3">

        <div class="content-panel">

          <!-- Start: Payments part of the default form -->
          <form [formGroup]="defaultForm">

            <table class="simple-form">
              <tr>
                <th><label for="trial-code">Status</label></th>
                <td>
                  <div class="form-group">
                    <app-dropdown-input #stateElement id="state" [options]="states" [parentForm]="defaultForm"
                                        name="state" [selectedValue]="defaultForm.get('state').value"
                                        (selectValueChanged)="onStateChanged($event)"></app-dropdown-input>
                  </div>
                </td>
              </tr>
              <tr *ngIf="authService.hasPermission(Permissions.TrialEditSensitive)">
                <th style="width: 145px;"><label class="required" for="protocol-number">Protocol Number</label></th>
                <td>
                  <div class="form-group">
                    <input type="text" id="protocol-number" class="form-control" formControlName="protocolNumber">
                  </div>
                </td>
              </tr>
              <tr *ngIf="authService.hasPermission(Permissions.TrialEditPublic)">
                <th style="width: 145px;"><label class="required" for="trial-code">Public Trial Code</label></th>
                <td>
                  <div class="form-group">
                    <input type="text" id="trial-code" class="form-control" formControlName="code">
                  </div>
                </td>
              </tr>
              <tr>
                <th><label class="required" for="trial-internal-code">Oracle Code</label></th>
                <td>
                  <div class="form-group">
                    <input type="text" id="trial-internal-code" class="form-control" formControlName="internalCode">
                  </div>
                </td>
              </tr>
              <tr *ngIf="authService.hasPermission(Permissions.TrialEditSensitive)">
                <th><label class="required" for="client">Client</label></th>
                <td>
                  <div class="form-group">
                    <input type="text" id="client" class="form-control" formControlName="client">
                  </div>
                </td>
              </tr>
              <tr *ngIf="authService.hasPermission(Permissions.TrialEditSensitive)">
                <th><label for="sponsor">Sponsor</label></th>
                <td>
                  <div class="form-group">
                    <input type="text" id="sponsor" class="form-control" formControlName="sponsor">
                  </div>
                </td>
              </tr>
              <tr>
                <th><label for="nickname">Nickname</label></th>
                <td>
                  <div class="form-group">
                    <input type="text" id="nickname" class="form-control" formControlName="nickname">
                  </div>
                </td>
              </tr>
              <tr>
                <th><label class="required" for="opportunity-number">Opportunity Number</label></th>
                <td>
                  <div class="form-group">
                    <input type="text" id="opportunity-number" class="form-control" formControlName="opportunityNumber">
                  </div>
                </td>
              </tr>
              <tr *ngIf="authService.hasPermission(Permissions.TrialEditSensitive)">
                <th><label for="trial-name">Trial Name</label></th>
                <td>
                  <div class="form-group">
                    <input type="text" id="trial-name" class="form-control" formControlName="name">
                  </div>
                </td>
              </tr>
              <tr>
                <th class="valign-top"><label>Countries</label></th>
                <td>
                  <div class="form-group">
                    <mat-select formControlName="countries" multiple placeholder="Select Countries...">
                      <mat-option *ngFor="let country of countries" [value]="country.code">
                        {{
 country.name
                        }}
                      </mat-option>
                    </mat-select>
                  </div>

                  <ul class="country-tags">
                    <li *ngFor="let country of selectedCountries">
                      {{ country.name }} <a (click)="removeCountryFromTrial(country.code)">
                        <i class="fas fa-times"
                           aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th class="valign-top"><label class="required">Budget Currency</label></th>
                <td>
                  <div class="form-group">
                    <app-autosuggest-dropdown-input #baseCurrencySelect
                                                    [parentForm]="defaultForm"
                                                    [options]="currencyOptions"
                                                    controlName="baseCurrency"
                                                    placeholder="Select Base Currency..."
                                                    (selectValueChanged)="onBaseCurrencySelected($event)">
                    </app-autosuggest-dropdown-input>
                  </div>
                </td>
              </tr>
              <tr>
                <th><label for="visit-count">Number of Visits</label></th>
                <td>
                  <div class="form-group">
                    <app-dropdown-input #visitCountElement id="visit-count" [options]="visitCountOptions"
                                        [parentForm]="defaultForm" name="visitCount"
                                        [selectedValue]="defaultForm.get('visitCount').value"></app-dropdown-input>
                  </div>
                </td>
              </tr>
              <tr *ngIf="authService.hasPermission(Permissions.TrialEditSensitive)">
                <th><label for="description">Protocol Title</label></th>
                <td>
                  <div class="form-group">
                    <textarea class="form-control" id="description" formControlName="description"
                              placeholder="Add a brief overview or description of the trial ..."></textarea>
                  </div>
                </td>
              </tr>
              <tr *ngIf="authService.hasPermission(Permissions.TrialEditSensitive)">
                <th><label for="indications">Indications</label></th>
                <td>
                  <div class="form-group">
                    <textarea class="form-control" id="indications" formControlName="indications"></textarea>
                  </div>
                </td>
              </tr>
              <tr *ngIf="authService.hasPermission(Permissions.TrialEditSensitive)">
                <th><label>Therapeutic Area</label></th>
                <td>
                  <div class="form-group">
                    <app-dropdown-input [options]="therapeuticAreaOptions" placeholder="Select..."
                                        [parentForm]="defaultForm" (selectValueChanged)="onTherapeuticAreaChanged($event)"
                                        #therapeuticAreaSelect controlName="therapeuticArea"></app-dropdown-input>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <label>IMS More4Apps Supplier Site</label>
                </th>
                <td>
                  <div class="form-group">
                    <app-list-autocomplete #imsSupplierList [parentForm]="defaultForm" controlName="imsSupplier" listType="ImsSupplier"></app-list-autocomplete>
                  </div>
                </td>
              </tr>
              <tr>
                <th><label for="description">Expense Policy Reminders</label></th>
                <td>
                  <div class="form-group">
                    <textarea class="form-control" id="expensePolicyReminders" formControlName="expensePolicyReminders"
                              placeholder="Add a brief reminder of the trial's expense policy"></textarea>
                  </div>
                </td>
              </tr>
              <tr>
                <th><label class="required" for="trial-name">Coordinator Email Address</label></th>
                <td>
                  <div class="form-group">
                    <input type="email" id="coordinator-email" class="form-control" formControlName="coordinatorEmail">
                  </div>
                </td>
              </tr>
              <tr>
                <th><label class="required" for="trial-name">Trial Terms & Conditions URL</label></th>
                <td>
                  <div class="form-group">
                    <input type="text" id="terms-url" class="form-control" formControlName="termsUrl">
                  </div>
                </td>
              </tr>
              <tr>
                <th><label class="required" for="trial-name">Trial Privacy Policy URL</label></th>
                <td>
                  <div class="form-group">
                    <input type="url" id="privacy-policy-url" class="form-control" formControlName="privacyPolicyUrl">
                  </div>
                </td>
              </tr>
              <tr>
                <th><label class="required" for="visit-count">Translation Version</label></th>
                <td>
                  <div class="form-group">
                    <app-dropdown-input #translationVersionDropdown id="translation-version" [options]="versionOptions"
                                        [parentForm]="defaultForm" name="translationVersionId" placeholder="Select Version...">
                    </app-dropdown-input>
                  </div>
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <div class="checkbox-wrap" *ngIf="trialDashboardEnabled" [class.visibility-hidden]="true">
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="dashboardEnabled" formControlName="dashboardEnabled" value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="dashboardEnabled">Enable Dashboard</label>
                    </div>
                  </div>

                  <div class="checkbox-wrap">
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="rideHealthEnabled" formControlName="rideHealthEnabled" value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="rideHealthEnabled">Enable Ride Health</label>
                    </div>
                  </div>

                  <div class="checkbox-wrap">
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="muvEnabled" formControlName="muvEnabled" value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="muvEnabled">Enable MUV</label>
                    </div>
                  </div>

                  <div class="checkbox-wrap">
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="allowActivityCentre" formControlName="allowActivityCentre"
                               value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="allowActivityCentre">Enable Activity Centre</label>
                    </div>
                  </div>

                  <div class="checkbox-wrap">
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="enableSmartNotifications" formControlName="enableSmartNotifications"
                               value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="enableSmartNotifications">Enable Smart Notifications</label>
                    </div>
                  </div>

                  <div class="checkbox-wrap">
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="allowFeedbackRequests" formControlName="allowFeedbackRequests"
                               value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="allowFeedbackRequests">Allow Feedback Requests</label>
                    </div>
                  </div>

                  <div class="checkbox-wrap">
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="bookingEnabled" formControlName="bookingEnabled" value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="bookingEnabled">Booking Enabled</label>
                    </div>
                  </div>

                  <div class="checkbox-wrap">
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="bankAccountEnabled" formControlName="bankAccountEnabled" value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="bankAccountEnabled">Pfizer - 7051591 Bank Acccount Details Enabled</label>
                    </div>
                  </div>

                  <div class="checkbox-wrap">
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="bankAccountSelfManagementEnabled" formControlName="bankAccountSelfManagementEnabled" value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="bankAccountSelfManagementEnabled">Bank Account in App v2</label>
                    </div>
                  </div>

                  <div class="checkbox-wrap">
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="bankAccountSelfCardManagementEnabled" formControlName="bankAccountSelfCardManagementEnabled" value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="bankAccountSelfCardManagementEnabled">Card Management in App v2</label>
                    </div>
                  </div>

                  <div class="checkbox-wrap">
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="patientAutoCompletion" formControlName="patientAutoCompletion">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="patientAutoCompletion">
                        Patient Auto Completion
                        <span *ngIf="!trial.visitTemplateExists" class="checkbox-note">
                          *Note: There is no visit schedule yet for new trials so Auto Completion won't apply.
                        </span>
                      </label>
                    </div>
                  </div>

                  <div class="checkbox-wrap last">
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="diaryEnabled" formControlName="diaryEnabled" value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="diaryEnabled">Enable Maze E-Diary</label>
                    </div>
                  </div>
                </td>
              </tr>

              <tr *ngIf="defaultForm.get('apiEnabled').value">
                <th class="valign-top"><label class="required">Patient Email Template</label></th>
                <td>
                  <div class="checkbox-wrap last">
                    <div class="form-group">
                      <label class="checkbox">
                        <input type="checkbox" id="emailPatientOnVisitCreation" formControlName="emailPatientOnVisitCreation" value="true">
                        <span></span>
                      </label>
                      <label class="checkbox-label" for="emailPatientOnVisitCreation">Email Patient on Visit Creation</label>
                    </div>
                  </div>
                </td>
              </tr>

              <tr *ngIf="defaultForm.get('emailPatientOnVisitCreation').value && defaultForm.get('apiEnabled').value">
                <th class="valign-top"><label class="required">Patient Email Template</label></th>
                <td>
                  <div class="form-group">
                    <mat-select formControlName="requestTravelEmailTemplate" placeholder="Select Template...">
                      <mat-option *ngFor="let template of emailTemplateOptions" [value]="template.id">{{ template.value }}</mat-option>
                    </mat-select>
                  </div>
                </td>
              </tr>

              <tr *ngIf="defaultForm.get('emailPatientOnVisitCreation').value && defaultForm.get('apiEnabled').value">
                <th><label class="required" for="magicLinkExpiryDays">Magic Link Expiry (Days)</label></th>
                <td>
                  <div class="form-group">
                    <input type="number" id="magicLinkExpiryDays" class="form-control" formControlName="magicLinkExpiryDays">
                  </div>
                </td>
              </tr>
            </table>
          </form>
          <!-- End: Default form -->
          <!-- Start: Buttons -->
          <table class="simple-form">
            <tr>
              <th style="width: 140px;"></th>
              <td>
                <div class="btn-wrap">
                  <div class="row">
                    <div class="col-6">
                      <a routerLink="/trial/{{ trial.id }}" class="btn btn-secondary">Cancel</a>
                    </div>
                    <div class="col-6">
                      <button type="submit" class="btn btn-primary" (click)="onFormSubmit()"
                              [disabled]="!defaultForm.valid">
                        <span *ngIf="formIsProcessing">
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="sr-only">Loading...</span>
                        </span>
                        <span *ngIf="!formIsProcessing">Save</span>
                      </button>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
          <!-- End: Buttons -->

        </div>

      </div>
    </div>

  </div>
  <!-- End: View body -->

</div>
